'use client';

import FaIcon, { faChevronDown, faChevronUp } from '@/components/icon/FaIcon/FaIcon';
import utilsStyles from '@/styles/modules/utils.module.scss';
import { useState } from 'react';
import Accordion from '../Accordion/Accordion';
import styles from './ResaleProhibited.module.scss';

function ResaleProhibited() {
  const [isOpened, setIsOpened] = useState(false);

  const toggleAccordion = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsOpened(!isOpened);
  };

  return (
    <div className={styles.componentStyle}>
      <button type='button' className={`${styles.head} ${utilsStyles.textBold}`} onClick={(e) => toggleAccordion(e)}>
        <span>※獲得した景品の転売、デジタルコンテンツの無断での公開は禁止しております。</span>
        &nbsp;
        <span>{isOpened ? <FaIcon icon={faChevronUp} /> : <FaIcon icon={faChevronDown} />}</span>
      </button>
      <Accordion isOpened={isOpened} startHeight={0}>
        <div className={styles.bodyContainer}>
          <p className={styles.body}>
            ・本サービスで獲得された景品をオークション等へ出品する行為、その他営利目的での転売行為は禁止しております。
            <br />
            ・本サービスで獲得された動画･画像･ボイス等のデジタルコンテンツは、出品者が著作権を有しております。無断でのSNS等での公開、譲渡、その他著作権を侵害する行為は禁止しております。
            <br />
            ・当選権利は当選者ご本人のみ有効となります。当選権利の譲渡、オークション等への出品、その他営利目的での転売は禁止しております。
            <br />
          </p>
        </div>
      </Accordion>
    </div>
  );
}

export default ResaleProhibited;
